import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';


class FileUpload extends Component {
  constructor () {
    super();
    this.link = {
      url: null,
      label: 'slow looking'
    }

    this.state = {
      file: null
    };
  }

  submitFile = (event) => {
    event.preventDefault();
    document.getElementById("button").disabled = true;

    const formData = new FormData();
    //formData.append('file', window.honeyfile[0]);
    formData.append('file', this.state.file[0]);
    axios.post(`https://hs-image-in.herokuapp.com/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      // handle your response;
      console.log(response);

      const origFilestemComponents = this.state.file[0].name.split('.');
      const origFilestem = origFilestemComponents[0];
      this.link.url = 'https://hsimages.cogapp.com/iiif/' + origFilestem + '.ptif/full/full/0/default.jpg';
      
      this.render(this.link.url);

    }).catch(error => {
      // handle your error
      console.log(error);
    });
  }

  handleFileUpload = (event) => {
    this.setState({file: event.target.files});
  }

  render (link) {

    if(link){
      const element = (
        <div className="container">
          <h1>IIIF Image Converter</h1>
            <div className="success-message"><strong>Image successfully uploaded</strong><br /><br />Your image will be available at the following link in around 30 seconds <a href={this.link.url}>{this.link.url}</a></div>
            <h4><a href="https://cogapp.com/iiif">Find out more about Cogapp's IIIF and Enrichment Services</a></h4>
        </div>
        );
      ReactDOM.render(element, document.getElementById('root'));
    }
    else {
      return (
        <div className="container">
          <h1>IIIF Image Converter</h1>
          <form onSubmit={this.submitFile}>
            <input label='upload file' type='file' onChange={this.handleFileUpload} />
            <button id='button' type='submit'>Send</button>
          </form>
          <h4><a href="https://cogapp.com/iiif">Find out more about Cogapp's IIIF and Enrichment Services</a></h4>
        </div>
      );
    }
    
  }
}

export default FileUpload;
